<template>
  <div class="line-OAuth-callback d-flex flex-row">
    <v-overlay :value="loadingBind">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card
      v-if="!loadingBind && result"
      class="ma-auto ems-opacity-50-bg"
      width="360"
      flat
      elevation="2"
    >
      <v-card-title>
        <v-icon large class="indigo--text text--darken-1t"
          >mdi-check-bold</v-icon
        >
        <span class="ml-3 display-1 indigo--text text--darken-1">綁定成功</span>
      </v-card-title>
      <v-card-text> 已完成LINE帳號綁定。 </v-card-text>
      <v-card-actions>
        <router-link
          class="mx-auto text-decoration-none indigo--text text--darken-1"
          to="/"
          >回首頁</router-link
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import LINE from '@/api/LINE'
import Swal from 'sweetalert2'

export default {
  mixins: [],
  components: {},
  props: {
    code: {
      typr: String,
      required: true
    }
  },
  data: function () {
    return {
      loadingBind: true,
      result: false
    }
  },
  computed: {},
  watch: {},
  created: function () {},
  async mounted() {
    await this.userBind()
  },
  destroyed() {},
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show'
    }),
    userBind() {
      this.result = false
      this.loadingBind = true
      LINE.userBind(this.code)
        .then((response) => {
          console.log(response)
          this.result = true
        })
        .catch((error) => {
          console.log(error)
          Swal.fire({
            text: `LINE帳號綁定失敗，請重新作業。`,
            icon: 'error',
            confirmButtonColor: '#3085d6'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ path: '/line/OAuth' })
            }
          })
        })
        .finally(() => {
          this.loadingBind = false
        })
    }
  }
}
</script>

<style scoped>
.line-OAuth-callback {
  height: 100vh;
}
</style>
