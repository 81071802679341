import settings from '@/settings'

/**
 * LINE 相關 API
 */
class _LINE {
  /**
   * OAuth 綁定使用者(使用者必須為登入狀態)
   */
  userBind(code) {
    return axios.post(settings.api.fullPath + `/user/line/bind/${code}`, {})
  }
}

var LINE = new _LINE()
export default LINE
